var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CDataTableDev',{attrs:{"footer":true,"columnFilter":false,"hover":_vm.hover,"striped":_vm.striped,"border":_vm.border,"small":_vm.small,"fixed":_vm.fixed,"items":_vm.items,"fields":_vm.fields,"column-filter":"","items-per-page-select":"","items-per-page":10,"sorter":"","pagination":"","table-filter":"","cleaner":"","actions":_vm.actions,"buttonEdit":_vm.buttonEdit,"buttonDelete":_vm.buttonDelete,"withActions":_vm.withActions,"myButtons":_vm.myButtons},on:{"mtd_action_mybutton":_vm.mtd_action_mybutton,"action_edit":_vm.action_edit,"action_delete":_vm.action_delete},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"show_details",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleDetails(item, index)}}},[_vm._v(" "+_vm._s(Boolean(item._toggled) ? "Hide" : "Show")+" ")])],1)]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":_vm.collapseDuration}},[_c('CCardBody',[_c('CMedia',{attrs:{"aside-image-props":{ height: 102 }}},[_c('h4',[_vm._v(" "+_vm._s(item.username)+" ")]),_c('p',{staticClass:"text-muted"},[_vm._v("User since: "+_vm._s(item.registered))]),_c('CButton',{attrs:{"size":"sm","color":"info"}},[_vm._v(" User Settings ")]),_c('CButton',{staticClass:"ml-1",attrs:{"size":"sm","color":"danger"}},[_vm._v(" Delete ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }